<template>
  <!-- src: ShopRegisterIndex -->
  <v-container style="padding-bottom: 24px">
    <ShopCommonTopBar />

    <v-row align="center" class="flex-column">
      <v-col cols="12" lg="6" md="8" sm="10" class="mx-auto">
        <v-form lazy-validation @submit.prevent="toInformation">
          <v-card elevation="0">
            <v-card-title style="padding: 0 0 12px">
              <span class="text-h6 fw-600">
                {{
                  isShowPhone
                    ? $t('a_verification_code_has_been_sent_to_your_phone')
                    : $t('a_verification_code_has_been_sent_to_your_email')
                }}
              </span>
              <br />
              <span class="text-h6 fw-600" style="color: #f5a100">
                {{ sendNumber }}
              </span>
            </v-card-title>
            <v-card-text style="padding: 16px 0">
              <!-- Verification Code -->
              <div class="mb-1">
                <div class="fs-13 fw-500 card-text">
                  {{ $t('verification_code') }}
                </div>

                <v-row dense>
                  <v-col>
                    <v-otp-input
                      v-model="form.verificationCode"
                      length="5"
                      type="number"
                      :error-messages="verificationCodeErrors"
                      hide-details="auto"
                      :disabled="loading"
                      required
                    ></v-otp-input>
                  </v-col>
                </v-row>
              </div>

              <v-row class="justify-end">
                <v-col cols="6">
                  <v-btn
                    :disabled="this.countdownSeconds !== 60"
                    block
                    color="primary"
                    elevation="0"
                    @click.stop.prevent="onSend"
                  >
                    {{ countdownText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row style="margin-top: 100px">
            <v-col cols="5">
              <v-btn
                x-large
                class="px-12 mb-4 w-100"
                elevation="0"
                color="#E7E7E7"
                @click.stop="routeBack"
                style="color: #a8a8a8"
              >
                <i
                  class="la la-arrow-left fs-20"
                  style="position: absolute; left: -40px"
                ></i>
                {{ $t('back_to') }}
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                x-large
                class="px-12 mb-4 w-100"
                elevation="0"
                type="submit"
                color="primary"
                :loading="loading"
                :disabled="loading"
              >
                {{ $t('next') }}

                <i
                  class="la la-arrow-right fs-20"
                  style="position: absolute; right: -40px"
                ></i>
              </v-btn>
            </v-col>
          </v-row>

          <div class="mb-4 text-center" style="color: #a8a8a8">
            {{ $t('by_signing_up_you_agree_to_our') }}
            <router-link
              :to="{
                name: 'CustomPage',
                params: { pageSlug: 'terms' }
              }"
              class="primary--text text-decoration-underline"
            >
              {{ $t('terms_and_conditions') }}
            </router-link>
          </div>

          <div class="text-center text-decoration-underline fs-14">
            <a :href="contactUrl" target="_blank" class="text-reset opacity-60">
              <!-- <i class="la la-phone" /> -->
              <span>{{ $t('help_line_link') }}</span>
              <!-- <span>{{ data.helpline }}</span> -->
            </a>
          </div>
        </v-form>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogSwitch" persistent width="310px">
      <v-card class="dialog-container">
        <div class="dialog-close c-pointer" @click="dialogSwitch = false">
          <i class="la la-close fs-20"></i>
        </div>

        <!-- todo: 百分比 -->
        <drag-verify-img-chip
          ref="dvicChip"
          :imgsrc="require('@/assets/img/verified_image.png')"
          :isPassing.sync="isPassing"
          :barWidth="40"
          :text="$t('please_hold_down_the_slider_and_drag')"
          :successText="$t('verificate_pass')"
          :failTip="$t('verification_fails_tips')"
          :successTip="
            $t('verification_passed_more_than_of_users', { scale: '85%' })
          "
          handlerIcon="la la-arrow-right"
          successIcon="la la-check"
          @refresh="refreshVerifyImage"
          @passcallback="verificationPass"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { requiredIf, maxLength } from 'vuelidate/lib/validators'

// component
import ShopCommonTopBar from '@/components/shop/ShopCommonTopBar'
import DragVerifyImgChip from '@/components/auth/DragVerifyImgChip'

// vuex
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'ShopRegisterIndex',

  components: {
    ShopCommonTopBar,
    DragVerifyImgChip
  },

  data() {
    return {
      data: {},

      form: {
        verificationCode: ''
      },

      // 图片验证是否通过
      isPassing: false,

      // dialog 开关
      dialogSwitch: false,

      isShowPhone: false,

      isShowEmail: false,

      loading: false,

      // 倒计时描述
      countdownSeconds: 60,

      sendNumber: ''
    }
  },

  computed: {
    ...mapGetters('app', [
      'h5Url',
      'getSellerH5Url',
      'allLanguages',
      'userLanguageObj',
      'availableCountries',
      'contactUrl'
    ]),

    countdownText() {
      return this.countdownSeconds === 60
        ? this.$t('resend_code')
        : `${this.countdownSeconds}s`
    },

    verificationCodeErrors() {
      const errors = []
      if (!this.$v.form.verificationCode.$dirty) return errors
      !this.$v.form.verificationCode.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.verificationCode.maxLength &&
        errors.push(this.$i18n.t('verification_code_is_5_characters'))
      return errors
    }
  },

  validations: {
    form: {
      verificationCode: {
        required: requiredIf(function () {
          return this.registerVerifySwitch
        }),
        maxLength: maxLength(5)
      }
    }
  },

  methods: {
    ...mapMutations('auth', ['setReferralCode']),
    ...mapActions('app', ['setLanguage']),

    // 验证图片刷新
    refreshVerifyImage() {},

    // 验证通过
    verificationPass() {
      this.countdown()

      setTimeout(() => {
        this.dialogSwitch = false

        this.sendVerificationCode()
      }, 1000)
    },

    routeBack() {
      this.$router.back()
    },

    async toInformation() {
      this.$v.form.$touch()

      if (this.$v.form.verificationCode.$error) return

      try {
        const methods = this.isShowPhone ? 'sms/verify' : 'email/verify'
        const type = this.isShowEmail ? 'email' : 'phone'

        const data = {
          tag: 'register',
          code: this.form.verificationCode,
          again: 1
        }
        if (type === 'email') {
          data.email = this.sendNumber
        } else {
          data.phone = this.sendNumber
        }

        const {
          data: { result, message }
        } = await this.call_api('post', methods, data)

        if (result) {
          const params = {
            name: 'ShopPersonalInformation',

            query: {
              type: type,
              verificationCode: this.form.verificationCode,
              value: this.sendNumber
            }
          }

          // 如果有邀请码带上邀请码
          if (this.referralCode) {
            params.query.referral_code = this.referralCode
          }

          this.$router.push(params)
        } else {
          this.snack({
            message,
            color: 'red'
          })
        }
      } catch (e) {
        this.snack({
          message: this.$t('verification_code_has_send_fail'),
          color: 'red'
        })
      }
    },

    // 根据当前的注册，自动发送对应的验证码
    async sendVerificationCode() {
      try {
        const methods = this.isShowPhone ? 'sms/send' : 'email/send'

        const params = {
          tag: 'register'
        }

        if (this.isShowPhone) {
          params.phone = this.sendNumber
        } else {
          params.email = this.sendNumber
        }

        const {
          data: { result, message }
        } = await this.call_api('post', methods, params)

        if (result) {
          return this.snack({
            message: this.$t('verification_code_has_send_success'),
            color: 'green'
          })
        }

        this.snack({
          message,
          color: 'red'
        })
      } catch (error) {
        console.log('send verification code fail: ', error)

        this.snack({
          message: this.$t('verification_code_has_send_fail'),
          color: 'red'
        })
      }
    },

    // 倒计时
    countdown() {
      this.countdownSeconds--

      this.interval = setInterval(() => {
        this.countdownSeconds--

        // 倒计时秒数为 0，则清空计时器
        if (!this.countdownSeconds) {
          clearInterval(this.interval)
          this.countdownSeconds = 60

          return
        }
      }, 1000)
    },

    onSend() {
      this.verificationPass()
      // this.dialogSwitch = true

      // this.$nextTick(() => {
      //   this.$refs.dvicChip.reset()
      // })
    },

    routeToUser() {
      this.$router.push({ name: 'Home' })
    },

    routeToLogin() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        window.location.href = this.getSellerH5Url
      } else {
        window.location.href = this.h5Url
      }
    },

    routeToRegister() {
      this.$router.push({ name: 'ShopInformation' })
    },

    switchMode() {
      if (this.isShowEmail) {
        this.isShowPhone = true
        this.isShowEmail = false
      } else {
        this.isShowPhone = false
        this.isShowEmail = true
      }
    }
  },

  created() {
    const query = this.$route.query

    if (!query) {
      return this.routeBack()
    }

    const { type, value, referral_code } = query

    if (type === 'email') {
      this.isShowEmail = true
    } else if (type === 'phone') {
      this.isShowPhone = true
    }

    if (value) {
      this.sendNumber = value
    }

    this.countdown()

    if (referral_code) {
      this.referralCode = referral_code
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  padding: 30px;
}

.dialog-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px;
}

::v-deep .drag-verify-container {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

::v-deep .theme--light.v-card {
  background: transparent !important;
}

.card-text {
  color: #000;
}
</style>
